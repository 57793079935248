import React, { createContext, useContext, useEffect, useState }  from 'react';

const ThemeContext = createContext({
    mode: '',
    toggleMode: () => {}
});

const Provider = ({children})=>{
    const [mode, setMode] = useState('light');
    
    const handleModeChange = mode => {
        localStorage.setItem('mode', mode)
        setMode(mode)
        mode === 'dark' ? document.documentElement.classList.add('dark') : document.documentElement.classList.remove('dark');
    };

    useEffect(() => {
        const localTheme = localStorage.getItem('mode');
        if (localTheme) {
            setMode(localTheme);
        } else {
            setMode('light')
        }
    }, []);
    
    const toggleMode = () =>{
        if (mode === 'light') {
            handleModeChange('dark')
        } else {
            handleModeChange('light')
        }
    }
        
    return (
        <ThemeContext.Provider value={{mode: mode, toggleMode: toggleMode}}>
            {children}
        </ThemeContext.Provider>
    );
}

export default Provider;

export const useTheme = () => useContext(ThemeContext);